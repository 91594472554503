import { Navbar, Main, Footer, Category } from "../components";

function Home() {
  return (
    <>
      <Navbar />
      <Main />
      <Category />
      <Footer />
    </>
  )
}

export default Home