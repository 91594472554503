import React from 'react'
import { Footer, Navbar, SubCategory} from "../components"

const SubCategories = () => {
  return (
    <>
      <Navbar />
      <SubCategory />
      <Footer />
    </>
  )
}

export default SubCategories