import React from 'react'
import { Footer, Navbar, ProductDetail } from "../components"

const ProductsDetail = () => {
  return (
    <>
      <Navbar />
      <ProductDetail />
      <Footer />
    </>
  )
}

export default ProductsDetail