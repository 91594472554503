import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import "./category.css";

import { Link } from "react-router-dom";

const Category = () => {
    
  const ShowCategory = () => {
    return (
      <>
 

        <div>
			<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>
        <div class="container" >
        <div className="row">
            <div class="col-md-4">
            <Link rel="canonical" to="/productos/trofeos-de-vidrio">
              <div class="card card-background zoom">               
                    <img alt="trofeo_de_vidrio_capela_bimetal" src="assets/categorias/trofeo_de_vidrio_capela_bimetal.jpg" backgroundSize="cover" backgroundPosition="50% 50%" /> 
                        <div class="filter"></div>                     
                    <div class="content">
                        <h5 class="price">Trofeos de Vidrio
                        </h5> 
                    </div>
                    <div class="footer">
                      <div class="author">
                            <a href="#/">
                              <span>Dey-Dey</span>
                            </a>
                        </div>
                      <div class="stats pull-right">
                            <i class="fa fa-eye"></i>                              
                      </div>                           
                    </div>
                  
                </div>
                </Link>
            </div>
            
            <div class="col-md-4">
            <Link rel="canonical" to="/sub-categoria/placas-de-inauguracion">
            <div class="card card-background zoom">               
                    <img alt="placa_de_vidrio_letrero" src="assets/categorias/placa_de_inauguración.jpg" backgroundSize="cover" backgroundPosition="50% 50%" /> 
                        <div class="filter"></div>                     
                    <div class="content">
                        <h5 class="price">Placas de Inauguración
                        </h5> 
                    </div>
                    <div class="footer">
                      <div class="author">
                            <a href="#/">
                              <span>Dey-Dey</span>
                            </a>
                        </div>
                      <div class="stats pull-right">
                            <i class="fa fa-eye"></i>                              
                      </div>                           
                    </div>
                  
                </div>
                </Link>
            </div>
            
            <div class="col-md-4">
            <Link rel="canonical" to="/productos/trofeos-variados">
            <div class="card card-background zoom">               
                    <img alt="trofeo_de_madera_c" src="assets/categorias/trofeo_de_madera_c.jpg" backgroundSize="cover" backgroundPosition="50% 50%" /> 
                        <div class="filter"></div>                     
                    <div class="content">
                        <h5 class="price">Trofeos Variados
                        </h5> 
                    </div>
                    <div class="footer">
                      <div class="author">
                            <a href="#/">
                              <span>Dey-Dey</span>
                            </a>
                        </div>
                      <div class="stats pull-right">
                            <i class="fa fa-eye"></i>                              
                      </div>                           
                    </div>
                  
                </div>
                </Link>
            </div>
            
             
        </div>
        </div>
		</div>


      </>
    );
  };
  return (
    <>
      <div className="container my-3 py-3">
        <div className="row">
          <div className="col-12">
            <h2 className="display-5 text-center">Categoría de Productos</h2>
            <hr />
          </div>
        </div>
        <div className="row justify-content-center">
          {<ShowCategory />}          
        </div>
      </div>
    </>
  );
};

export default Category;
