import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import "./products.css";
import { useParams } from 'react-router-dom'
import { Link } from "react-router-dom";

const Products = () => {
  
  let { id } = useParams()

  
    const ShowTrofeoVidrio = () => {     
    return (
      <>            
         
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-vidrio-antorcha" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
           
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-vidrio/trofeo_de_vidrio_antorcha.jpg"
                  alt="Card" 
                />
                 
                <div className="card-body">
                <h5 className="card-title">
                   Trofeo de vidrio   
                  </h5>
                  <p className="card-text">
                  Antorcha
                  </p>
                </div>                
              </div>   
              </Link>
            </div>
          
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-vidrio-arco" style={{ textDecoration: 'none'}}> 
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-vidrio/trofeo_de_vidrio_arco.jpg"
                  alt="Card"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                   Trofeo de vidrio
                  </h5>
                  <p className="card-text">
                  Arco
                  </p>
                </div>                
              </div>  
              </Link>           
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-vidrio-capela-bimetal" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-vidrio/trofeo_de_vidrio_capela_bimetal.jpg"
                  alt="Card"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                   Trofeo de vidrio
                  </h5>
                  <p className="card-text">
                  Capela Bimetal
                  </p>
                </div>                
              </div> 
              </Link>            
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-vidrio-circular" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-vidrio/trofeo_de_vidrio_circular.jpg"
                  alt="Card"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                   Trofeo de vidrio
                  </h5>
                  <p className="card-text">
                  Circular
                  </p>
                </div>                
              </div>  
              </Link>           
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-vidrio-diamante" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-vidrio/trofeo_de_vidrio_diamante.jpg"
                  alt="Card"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                   Trofeo de vidrio
                  </h5>
                  <p className="card-text">
                  Diamante
                  </p>
                </div>                
              </div> 
              </Link>            
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-vidrio-gota" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-vidrio/trofeo_de_vidrio_gota.jpg"
                  alt="Card"
                   
                />
                <div className="card-body">
                <h5 className="card-title">
                   Trofeo de vidrio
                  </h5>
                  <p className="card-text">
                  Gota
                  </p>
                </div>                
              </div>   
              </Link>          
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-vidrio-soberin-gris" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-vidrio/trofeo_de_vidrio_soberin_gris.jpg"
                  alt="Card"
                   
                />
                <div className="card-body">
                <h5 className="card-title">
                   Trofeo de vidrio
                  </h5>
                  <p className="card-text">
                  Soberin Gris
                  </p>
                </div>                
              </div>  
              </Link>           
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-vidrio-estrella-polar-duo" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-vidrio/trofeo_de_vidrio_estrella_polar_duo.jpg"
                  alt="Card"
                 
                />
                <div className="card-body">
                <h5 className="card-title">
                   Trofeo de vidrio
                  </h5>
                  <p className="card-text">
                  Estrella Polar Duo
                  </p>
                </div>                
              </div>   
              </Link>          
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-vidrio-merlyn" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-vidrio/trofeo_de_vidrio_merlyn.jpg"
                  alt="Card"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                   Trofeo de vidrio
                  </h5>
                  <p className="card-text">
                  Merlyn
                  </p>
                </div>                
              </div>   
              </Link>          
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-vidrio-morelia-gris-metal-marmol" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-vidrio/trofeo_de_vidrio_morelia_gris_metal_marmol.jpg"
                  alt="Card"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                   Trofeo de vidrio
                  </h5>
                  <p className="card-text">
                  Morelia Gris Metal Marmol
                  </p>
                </div>                
              </div>    
              </Link>         
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-vidrio-octogonal" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-vidrio/trofeo_de_vidrio_octogonal.jpg"
                  alt="Card"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                   Trofeo de vidrio
                  </h5>
                  <p className="card-text">
                  Octogonal
                  </p>
                </div>                
              </div>       
              </Link>      
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-vidrio-rectangular" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-vidrio/trofeo_de_vidrio_rectangular.jpg"
                  alt="Card"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                   Trofeo de vidrio
                  </h5>
                  <p className="card-text">
                  Rectangular
                  </p>
                </div>                
              </div>   
              </Link>          
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-vidrio-soberin-transparente" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-vidrio/trofeo_de_vidrio_soberin_transparente.jpg"
                  alt="Card"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                   Trofeo de vidrio
                  </h5>
                  <p className="card-text">
                  Soberin Transparente
                  </p>
                </div>                
              </div>        
              </Link>     
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-vidrio-tetra" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-vidrio/trofeo_de_vidrio_tetra.jpg"
                  alt="Card"
                 
                />
                <div className="card-body">
                <h5 className="card-title">
                   Trofeo de vidrio
                  </h5>
                  <p className="card-text">
                  Tetra
                  </p>
                </div>                
              </div>    
              </Link>         
            </div>
      </>
    );
  };
  
  const ShowBronceFundido = () => {     
    return (
      <>            
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/placa-de-bronce-fundido" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/bronce-fundido/placa_de_bronce_fundido.jpg"
                  alt="placa_de_bronce_fundido"
                  height={200}
                />
                <div className="card-body">
                <h5 className="card-title">
                   Bronce Fundido   
                  </h5>
                  <p className="card-text">
                  Placa de Bronce Fundido
                  </p>
                </div>                
              </div> 
              </Link>            
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/placa-de-bronce-fundido-inen" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/bronce-fundido/placa_de_bronce_fundido_inen.jpg"
                  
                  alt="placa_de_bronce_fundido_inen"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                Bronce Fundido
                  </h5>
                  <p className="card-text">
                  Placa de Bronce Fundido INEN
                  </p>
                </div>                
              </div>   
              </Link>          
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/placa-de-bronce-fundido-pcm" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/bronce-fundido/placa_de_bronce_fundido_pcm.jpg"
                  
                  alt="placa_de_bronce_fundido_pcm"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                Bronce Fundido
                  </h5>
                  <p className="card-text">
                  Placa de Bronce Fundido PCM
                  </p>
                </div>                
              </div>  
              </Link>           
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/placa-de-bronce-fundido-puente" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/bronce-fundido/placa_de_bronce_fundido_puente.jpg"
                  
                  alt="placa_de_bronce_fundido_puente"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                Bronce Fundido
                  </h5>
                  <p className="card-text">
                  Placa de Bronce Fundido Puente
                  </p>
                </div>                
              </div>  
              </Link>           
            </div>
            
      </>
    );
  };

  
  const ShowLaminaBronce = () => {     
    return (
      <>            
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/placa-de-lamina-bronce" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/lamina-bronce/placa_de_lamina_bronce.jpg"
                  alt="placa_de_lamina_bronce"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                   Lámina Bronce   
                  </h5>
                  <p className="card-text">
                  Placa De Lamina Bronce
                  </p>
                </div>                
              </div>        
              </Link>     
            </div>                       
      </>
    );
  };

  
  const ShowMarmolGranito = () => {     
    return (
      <>            
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/marmol-claro" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/marmol-granito/marmol_claro.jpg"
                  alt="marmol_claro"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                   Marmol Granito  
                  </h5>
                  <p className="card-text">
                  Marmol Claro
                  </p>
                </div>                
              </div>         
              </Link>    
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/placa-carrara" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/marmol-granito/placa_carrara.jpg"
                  
                  alt="placa_carrara"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                Marmol Granito  
                  </h5>
                  <p className="card-text">
                  Placa Carrara
                  </p>
                </div>                
              </div>      
              </Link>       
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/placa-de-granito" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/marmol-granito/placa_de_granito.jpg"
                  
                  alt="placa_de_granito"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                Marmol Granito  
                  </h5>
                  <p className="card-text">
                  Placa De Granito
                  </p>
                </div>                
              </div>         
              </Link>    
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/placa-de-marmol" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/marmol-granito/placa_de_marmol.jpg"
                  
                  alt="placa_de_marmol"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                Marmol Granito  
                  </h5>
                  <p className="card-text">
                  Placa De Marmol
                  </p>
                </div>                
              </div>     
              </Link>        
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/placa-de-marmol-con-soportes" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/marmol-granito/placa_de_marmol_con_soportes.jpg"
                  
                  alt="placa_de_marmol_con_soportes"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                Marmol Granito  
                  </h5>
                  <p className="card-text">
                  Placa De Marmol Con Soportes
                  </p>
                </div>                
              </div>   
              </Link>          
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/placa-de-marmol-marfil" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/marmol-granito/placa_de_marmol_marfil.jpg"
                  
                  alt="placa_de_marmol_marfil"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                Marmol Granito  
                  </h5>
                  <p className="card-text">
                  Placa De Marmol Marfil
                  </p>
                </div>                
              </div>    
              </Link>         
            </div>
            
      </>
    );
  };

  
   
  const ShowPlacaAcrilica = () => {     
    return (
      <>            
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/placa-de-lamina-acrilica-plateada" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/placa-acrilica/placa_de_lamina_acrilica_plateada.jpeg"
                  alt="placa_de_lamina_acrilica_plateada"
                  
                />
                <div className="card-body">
                <h5 className="card-title">
                   Placa Acrílica   
                  </h5>
                  <p className="card-text">
                  Placa De Lamina Acrilica Plateada
                  </p>
                </div>                
              </div>  
              </Link>           
            </div>                       
      </>
    );
  };

  
  const ShowPlacaAcrilicaSerigrafia = () => {     
    return (
      <>            
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/placa-acrilica-serigrafia" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/placa-acrilica-serigrafia/placa_acrilica_serigrafia.jpeg"
                  alt="placa_acrilica_serigrafia"
                   
                />
                <div className="card-body">
                <h5 className="card-title">
                 Placa Acrilica Serigrafía  
                  </h5>
                  <p className="card-text">
                  Placa Acrilica Serigrafía
                  </p>
                </div>                
              </div>   
              </Link>          
            </div>                       
      </>
    );
  };

  
  const ShowPlacasVidrio = () => {     
    return (
      <>            
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/placa-de-vidrio" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/placas-de-vidrio/placa_de_vidrio.jpg"
                  alt="placa_de_vidrio"
                   
                />
                <div className="card-body">
                <h5 className="card-title">
                   Placas de Vidrio  
                  </h5>
                  <p className="card-text">
                  Placa De Vidrio
                  </p>
                </div>                
              </div> 
              </Link>            
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/placa-de-vidrio-letrero" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/placas-de-vidrio/placa_de_vidrio_letrero.jpg"
                  
                  alt="placa_de_vidrio_letrero"
                   
                />
                <div className="card-body">
                <h5 className="card-title">
                Placas de Vidrio
                  </h5>
                  <p className="card-text">
                  Placa De Vidrio Letrero
                  </p>
                </div>                
              </div>  
              </Link>           
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/placa-de-vidrio-mdf" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/placas-de-vidrio/placa_de_vidrio_mdf.jpg"
                  
                  alt="placa_de_vidrio_mdf"
                   
                />
                <div className="card-body">
                <h5 className="card-title">
                Placas de Vidrio  
                  </h5>
                  <p className="card-text">
                  Placa De Vidrio MDF
                  </p>
                </div>                
              </div>   
              </Link>          
            </div>
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/placa-de-vidrio-pavonado" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
                <img
                  className="card-img-top p-3"
                  src="../../assets/placas-de-inauguracion/placas-de-vidrio/placa_de_vidrio_pavonado.jpg"
                  
                  alt="placa_de_vidrio_pavonado"
                   
                />
                <div className="card-body">
                <h5 className="card-title">
                Placas de Vidrio 
                  </h5>
                  <p className="card-text">
                  Placa De Vidrio Pavonado
                  </p>
                </div>                
              </div>  
              </Link>           
            </div>
                   
            
      </>
    );
  };

  const ShowTrofeosVariados = () => {     
    return (
      <>            
         
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-madera-c" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
           
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-variados/trofeo_de_madera_c.jpg"
                  alt="Card" 
                />
                 
                <div className="card-body">
                <h5 className="card-title">
                  Trofeos Variados   
                  </h5>
                  <p className="card-text">
                  Trofeo de Madera C
                  </p>
                </div>                
              </div>   
              </Link>
            </div> 
            
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-madera-contry" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
           
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-variados/trofeo_de_madera_contry.jpg"
                  alt="Card" 
                />
                 
                <div className="card-body">
                <h5 className="card-title">
                  Trofeos Variados   
                  </h5>
                  <p className="card-text">
                  Trofeo de Madera Contry
                  </p>
                </div>                
              </div>   
              </Link>
            </div> 

            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-madera-elite" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
           
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-variados/trofeo_de_madera_elite.jpg"
                  alt="Card" 
                />
                 
                <div className="card-body">
                <h5 className="card-title">
                  Trofeos Variados   
                  </h5>
                  <p className="card-text">
                  Trofeo de Madera Elite
                  </p>
                </div>                
              </div>   
              </Link>
            </div> 
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-madera-eros" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
           
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-variados/trofeo_de_madera_eros.jpg"
                  alt="Card" 
                />
                 
                <div className="card-body">
                <h5 className="card-title">
                  Trofeos Variados   
                  </h5>
                  <p className="card-text">
                  Trofeo de Madera Eros
                  </p>
                </div>                
              </div>   
              </Link>
            </div> 
            <div className=" col-xl-2 col-xs-2 col-lg-3 col-md-4 col-sm-6 col-6 mb-4">
            <Link rel="canonical" to="/productos-detalle/trofeo-de-madera-trunca" style={{ textDecoration: 'none'}}>
              <div className="card text-center h-100">
           
                <img
                  className="card-img-top p-3"
                  src="../../assets/trofeos-variados/trofeo_de_madera_trunca.jpg"
                  alt="Card" 
                />
                 
                <div className="card-body">
                <h5 className="card-title">
                  Trofeos Variados   
                  </h5>
                  <p className="card-text">
                  Trofeo de Madera Trunca
                  </p>
                </div>                
              </div>   
              </Link>
            </div> 
      </>
    );
  };

  return (
    <>
    
      <div className="container my-3 py-3">
        <div className="row">
          <div className="col-12">
            <h2 className="display-5 text-center">
            {(() => {
              switch (id) {
                case 'trofeos-de-vidrio':
                  return 'Trofeos de Vidrio';
                case 'bronce-fundido':
                  return 'Bronce Fundido';
                case 'lamina-bronce':
                  return 'Lámina Bronce';
                case 'marmol-granito':
                  return 'Marmol Granito';
                case 'placa-acrilica':
                  return 'Placa Acrílica';
                case 'placa-acrilica-serigrafia':
                  return 'Placa Acrílica Serigrafía';
                case 'placas-de-vidrio':
                  return 'Placas de Vidrio';
                  case 'trofeos-variados':
                    return 'Trofeos Variados';
                default:
                  return null;
              }
            })()}  
            </h2>
            <hr />
          </div>
        </div>
        <div className="row justify-content-center">
        {(() => {
        switch (id) {
          case 'trofeos-de-vidrio':
            return <ShowTrofeoVidrio /> ;
          case 'bronce-fundido':
            return <ShowBronceFundido /> ;
          case 'lamina-bronce':
            return <ShowLaminaBronce /> ;
          case 'marmol-granito':
            return <ShowMarmolGranito /> ;
          case 'placa-acrilica':
            return <ShowPlacaAcrilica /> ;
          case 'placa-acrilica-serigrafia':
            return <ShowPlacaAcrilicaSerigrafia /> ;
          case 'placas-de-vidrio':
            return <ShowPlacasVidrio /> ;
          case 'trofeos-variados':
            return <ShowTrofeosVariados /> ;
          default:
            return null;
        }
      })()}  
        </div>
      </div>
    </>
  );
};

export default Products;
