import React from "react";
import { Footer, Navbar } from "../components";
const ContactPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Contacto</h1>
        <hr />
        <div class="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form name="contact" method="POST" netlify action="/" >
            <input type="hidden" name="form-name" value="contact" />
              <div class="form my-3">
                <label for="Nombres">Nombres</label>
                <input
                  type="text"
                  class="form-control"
                  id="nombres"
                  name="nombres"
                />
              </div>
              <div class="form my-3">
                <label for="Company">Empresa</label>
                <input
                  type="text"
                  class="form-control"
                  id="empresa"
                  name="empresa"
                />
              </div>
              <div class="form my-3">
                <label for="Email">Correo Electrónico</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  name="email"
                />
              </div>
              <div class="form my-3">
                <label for="Phone">Celular</label>
                <input
                  type="text"
                  class="form-control"
                  id="phone"
                  name="phone"
                />
              </div>
              <div class="form  my-3">
                <label for="comment">Comentario</label>
                <textarea
                  rows={5}
                  class="form-control"
                  email="comentario"
                  id="comentario"
                  name="comentario"
                />
              </div>
              <div className="text-center">
                <button
                  class="my-2 px-4 mx-auto btn btn-dark"
                  type="submit"                  
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
