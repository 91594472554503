import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import "./subcategory.css";

import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom'

const SubCategory = () => {
  let { id } = useParams()
  
  const ShowPlacasInauguracion = () => {
    return (
      <>
    

        <div>
       
			<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet"/>
        <div class="container" >
        <div className="row">

            <div class="col-md-4">
            <Link rel="canonical" to="/productos/bronce-fundido">
              <div class="card card-background zoom">               
                    <img alt="placa_de_bronce_fundido" src="../../assets/placas-de-inauguracion/bronce-fundido/placa_de_bronce_fundido.jpg" backgroundSize="cover" backgroundPosition="50% 50%" /> 
                        <div class="filter"></div>                     
                    <div class="content">
                        <h5 class="price">Bronce Fundido
                        </h5> 
                    </div>
                    <div class="footer">
                      <div class="author">
                            <a href="#/">
                              <span>Dey-Dey</span>
                            </a>
                        </div>
                      <div class="stats pull-right">
                            <i class="fa fa-eye"></i>                              
                      </div>                           
                    </div>
                  
                </div>
                </Link>
            </div>
            <div class="col-md-4">
            <Link rel="canonical" to="/productos/lamina-bronce">
              <div class="card card-background zoom">               
                    <img alt="placa_de_lamina_bronce" src="../../assets/placas-de-inauguracion/lamina-bronce/placa_de_lamina_bronce.jpg" backgroundSize="cover" backgroundPosition="50% 50%" /> 
                        <div class="filter"></div>                     
                    <div class="content">
                        <h5 class="price">Lamina Bronce
                        </h5> 
                    </div>
                    <div class="footer">
                      <div class="author">
                            <a href="#/">
                              <span>Dey-Dey</span>
                            </a>
                        </div>
                      <div class="stats pull-right">
                            <i class="fa fa-eye"></i>                              
                      </div>                           
                    </div>
                  
                </div>
                </Link>
            </div>
   
            <div class="col-md-4">
            <Link rel="canonical" to="/productos/marmol-granito">
              <div class="card card-background zoom">               
                    <img alt="placa_de_marmol_marfil" src="../../assets/placas-de-inauguracion/marmol-granito/placa_de_marmol_marfil.jpg" backgroundSize="cover" backgroundPosition="50% 50%" /> 
                        <div class="filter"></div>                     
                    <div class="content">
                        <h5 class="price">Marmol Granito
                        </h5> 
                    </div>
                    <div class="footer">
                      <div class="author">
                            <a href="#/">
                              <span>Dey-Dey</span>
                            </a>
                        </div>
                      <div class="stats pull-right">
                            <i class="fa fa-eye"></i>                              
                      </div>                           
                    </div>
                  
                </div>
                </Link>
            </div>

            <div class="col-md-4">
            <Link rel="canonical" to="/productos/placa-acrilica">
              <div class="card card-background zoom">               
                    <img alt="placa_de_lamina_acrilica_plateada" src="../../assets/placas-de-inauguracion/placa-acrilica/placa_de_lamina_acrilica_plateada.jpeg" backgroundSize="cover" backgroundPosition="50% 50%" /> 
                        <div class="filter"></div>                     
                    <div class="content">
                        <h5 class="price">Placa Acrílica
                        </h5> 
                    </div>
                    <div class="footer">
                      <div class="author">
                            <a href="#/">
                              <span>Dey-Dey</span>
                            </a>
                        </div>
                      <div class="stats pull-right">
                            <i class="fa fa-eye"></i>                              
                      </div>                           
                    </div>
                  
                </div>
                </Link>
            </div>
            <div class="col-md-4">
            <Link rel="canonical" to="/productos/placa-acrilica-serigrafia">
              <div class="card card-background zoom">               
                    <img alt="placa_acrilica_serigrafia" src="../../assets/placas-de-inauguracion/placa-acrilica-serigrafia/placa_acrilica_serigrafia.jpeg" backgroundSize="cover" backgroundPosition="50% 50%" /> 
                        <div class="filter"></div>                     
                    <div class="content">
                        <h5 class="price">Placa Acrílica Serigrafía
                        </h5> 
                    </div>
                    <div class="footer">
                      <div class="author">
                            <a href="#/">
                              <span>Dey-Dey</span>
                            </a>
                        </div>
                      <div class="stats pull-right">
                            <i class="fa fa-eye"></i>                              
                      </div>                           
                    </div>
                  
                </div>
                </Link>
            </div>

            <div class="col-md-4">
            <Link rel="canonical" to="/productos/placas-de-vidrio">
              <div class="card card-background zoom">               
                    <img alt="placa_de_vidrio_letrero" src="../../assets/placas-de-inauguracion/placas-de-vidrio/placa_de_vidrio_letrero.jpg" backgroundSize="cover" backgroundPosition="50% 50%" /> 
                        <div class="filter"></div>                     
                    <div class="content">
                        <h5 class="price">Placas De Vidrio
                        </h5> 
                    </div>
                    <div class="footer">
                      <div class="author">
                            <a href="#/">
                              <span>Dey-Dey</span>
                            </a>
                        </div>
                      <div class="stats pull-right">
                            <i class="fa fa-eye"></i>                              
                      </div>                           
                    </div>
                  
                </div>
                </Link>
            </div>

        </div>
        </div>
		</div>


      </>
    );
  };

  
  
  return (
    <>
      <div className="container my-3 py-3">  
        <div className="row">
          <div className="col-12">
            <h2 className="display-5 text-center">
            {(() => {
              switch (id) {
                case 'placas-de-inauguracion':
                  return 'Placas de Inauguración';
                case '2':
                  return null;
                case '3':
                  return null;
                default:
                  return null;
              }
            })()}  
            </h2>
            <hr />
          </div>
        </div>
        <div className="row justify-content-center"> 
          {(() => {
        switch (id) {
          case 'placas-de-inauguracion':
            return <ShowPlacasInauguracion /> ;
          case '2':
            return null;
          case '3':
            return null;
          default:
            return null;
        }
      })()}          
        </div>
      </div>
    </>
  );
};

export default SubCategory;
