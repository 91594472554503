import React from 'react'
import { Footer, Navbar } from "../components";
const AboutPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Nosotros</h1>
        <hr />
        <p className="lead text-center">
        Somos una empresa con años de experiencia y hemos atendido a diversas personas gracias a ello, tenemos una cartera de clientes tanto en el sector privado, estatal e individual a nivel nacional y extranjero, en general siempre todos nuestros clientes están satisfechos con nuestros productos.
        </p>
        <p className="lead text-center">
        Nuestro trabajo se basa en fabricar y ofrecer productos de alta calidad e innovación tenemos diseños convencionales pero también creativos y personalizados, incluso nos adaptándonos al gusto del cliente en cualquier modelo.
        </p>
        <p className="lead text-center">
        Para nosotros es muy importante quedar muy bien con nuestros clientes  por tanto ofrecemos en cualquiera de nuestros productos, garantía, calidad, puntualidad, atención personalizada, hasta llegar a obtener excelentes resultados que lo ayuden a resaltar y estar presente en sus más gratos momentos. 
        </p>
    
        <p className="lead text-center">
        ATTE, 
        </p>
        
        <p className="lead text-end">
          
        EL EQUIPO Y GERENCIA
        </p>
        <h2 className="text-center py-4">Our Products</h2>
        <div className="row">
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img className="card-img-top img-fluid" src="https://images.pexels.com/photos/298863/pexels-photo-298863.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Mens's Clothing</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img className="card-img-top img-fluid" src="https://images.pexels.com/photos/7679720/pexels-photo-7679720.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Women's Clothing</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img className="card-img-top img-fluid" src="https://images.pexels.com/photos/1927259/pexels-photo-1927259.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Jewelery</h5>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 mb-3 px-3">
            <div className="card h-100">
              <img className="card-img-top img-fluid" src="https://images.pexels.com/photos/356056/pexels-photo-356056.jpeg?auto=compress&cs=tinysrgb&w=600" alt="" height={160} />
              <div className="card-body">
                <h5 className="card-title text-center">Electronics</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AboutPage