import React from 'react'
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { Menu, MenuItem, MenuButton, SubMenu} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

const Navbar = () => {
    const state = useSelector(state => state.handleCart)
    return (
        <nav className="navbar navbar-expand-lg navbar-light sticky-top" style={{backgroundColor: "#FDFEFF"}}>
            <div className="container">
                <Link rel="canonical" className="navbar-brand fs-4 " to="/"> <img alt="Logo" src="../../assets/logo-detalles-peru.png" width="159px" height="96px" className="d-inline-block align-top" /> </Link>
               
                <button className="navbar-toggler mx-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto my-2 text-center">
                        <li className="nav-item">
                            <Link rel="canonical" className="nav-link" to="/">Inicio </Link>
                        </li>
                      
                        <Menu menuButton={<MenuButton className="nav-link" >Productos</MenuButton>}>
                            <MenuItem style={{ paddingLeft: '16px'}}><Link rel="canonical" className="nav-link" to="/productos/trofeos-de-vidrio">Trofeos de Vidrio</Link></MenuItem>
                            <SubMenu label="Placas de inauguración">
                                <MenuItem><Link rel="canonical" className="nav-link" to="/productos/bronce-fundido">Bronce Fundido</Link></MenuItem>
                                <MenuItem><Link rel="canonical" className="nav-link" to="/productos/lamina-bronce">Lámina Bronce</Link></MenuItem>
                                <MenuItem><Link rel="canonical" className="nav-link" to="/productos/marmol-granito">Marmol Granito</Link></MenuItem>
                                <MenuItem><Link rel="canonical" className="nav-link" to="/productos/placa-acrilica">Placa Acrílica</Link></MenuItem>
                                <MenuItem><Link rel="canonical" className="nav-link" to="/productos/placa-acrilica-serigrafia">Placa Acrílica Serigrafía</Link></MenuItem>
                                <MenuItem><Link rel="canonical" className="nav-link" to="/productos/placas-de-vidrio">Placas de Vidrio</Link></MenuItem>                                 
                            </SubMenu>
                            <MenuItem style={{ paddingLeft: '16px'}}><Link rel="canonical" className="nav-link" to="/productos/trofeos-variados">Trofeos Variados</Link></MenuItem>
                            
                            </Menu> 
                            
                         
                        <li className="nav-item">
                            <Link rel="canonical" className="nav-link" to="/nosotros">Nosotros</Link>
                        </li>
                        <li className="nav-item">
                            <Link rel="canonical" className="nav-link" to="/contacto">Contacto</Link>
                        </li>
                    </ul>
                    <div className="buttons text-center">
                    <FloatingWhatsApp 
                    phoneNumber='+51995533887' 
                    accountName='Detalles Perú'
                    statusMessage ='Asesor de Ventas'
                    chatMessage='¡Hola! Agradecidos de que contactes con Detalles Perú, en que podemos ayudarte.'

                    />
                      </div>
                </div>


            </div>
        </nav>
    )
}

export default Navbar