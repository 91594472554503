import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import "./productDetail.css";

import { useParams } from 'react-router-dom'
import ReactImageMagnify from "@blacklab/react-image-magnify";
 
 
 

 
const ProductDetail = () => {
  
  let { id } = useParams()

  var watchImg300 = "";
  var watchImg1200 = "";
  var nombreAlt = "";
  var titulo = "";
  var descripcion = "";

  var wimg = "";
  var himg = "";
  var wimgmax = "";
  var himgmax = "";
 
  
  {(() => {
    switch (id) {
      case 'trofeo-de-vidrio-antorcha': watchImg300='../../assets/trofeos-vidrio/trofeo_de_vidrio_antorcha_300.jpg';
                nombreAlt='trofeo de vidrio antorcha';
                titulo='Trofeo de Vidrio Antorcha';
                descripcion='Características del producto.';
                wimg=502; himg=768; wimgmax=1200;  himgmax=1832; 
        return  watchImg1200='../../assets/trofeos-vidrio/trofeo_de_vidrio_antorcha_1200.jpg';
      case 'trofeo-de-vidrio-arco': watchImg300='../../assets/trofeos-vidrio/trofeo_de_vidrio_arco_300.jpg';
                nombreAlt='trofeo de vidrio arco';
                titulo='Trofeo de Vidrio Arco';
                descripcion='Características del producto.';
                wimg=419; himg=625; wimgmax=1200;  himgmax=1832; 
        return  watchImg1200='../../assets/trofeos-vidrio/trofeo_de_vidrio_arco_1200.jpg';
      case 'trofeo-de-vidrio-capela-bimetal': watchImg300='../../assets/trofeos-vidrio/trofeo_de_vidrio_capela_bimetal_300.jpg';
                nombreAlt='trofeo de vidrio capela bimetal';
                titulo='Trofeo de Vidrio Capela Bimetal';
                descripcion='Características del producto.';
                wimg=419; himg=682; wimgmax=1200;  himgmax=1832; 
        return  watchImg1200='../../assets/trofeos-vidrio/trofeo_de_vidrio_capela_bimetal_1200.jpg';
      case 'trofeo-de-vidrio-circular': watchImg300='../../assets/trofeos-vidrio/trofeo_de_vidrio_circular_300.jpg';
                nombreAlt='trofeo de vidrio circular';
                titulo='Trofeo de Vidrio Circular';
                descripcion='Características del producto.';
                wimg=359; himg=380; wimgmax=1437;  himgmax=1520;  
        return  watchImg1200='../../assets/trofeos-vidrio/trofeo_de_vidrio_circular_1200.jpg';
      case 'trofeo-de-vidrio-diamante': watchImg300='../../assets/trofeos-vidrio/trofeo_de_vidrio_diamante_300.jpg';
                nombreAlt='trofeo de vidrio diamante';
                titulo='Trofeo de Vidrio Diamante';
                descripcion='Características del producto.';
                wimg=484; himg=613; wimgmax=1200;  himgmax=1832; 
        return  watchImg1200='../../assets/trofeos-vidrio/trofeo_de_vidrio_diamante_1200.jpg';
      case 'trofeo-de-vidrio-gota': watchImg300='../../assets/trofeos-vidrio/trofeo_de_vidrio_gota_300.jpg';
                nombreAlt='trofeo de vidrio gota';
                titulo='Trofeo de Vidrio Gota';
                descripcion='Características del producto.';
                wimg=459; himg=590; wimgmax=1200;  himgmax=1832; 
        return  watchImg1200='../../assets/trofeos-vidrio/trofeo_de_vidrio_gota_1200.jpg';
      case 'trofeo-de-vidrio-soberin-gris': watchImg300='../../assets/trofeos-vidrio/trofeo_de_vidrio_soberin_gris_300.jpg';
                nombreAlt='trofeo de vidrio soberin gris';
                titulo='Trofeo de Vidrio Soberin Gris';
                descripcion='Características del producto.';
                wimg=419; himg=565; wimgmax=1200;  himgmax=1832; 
        return  watchImg1200='../../assets/trofeos-vidrio/trofeo_de_vidrio_soberin_gris_1200.jpg';
      case 'trofeo-de-vidrio-estrella-polar-duo': watchImg300='../../assets/trofeos-vidrio/trofeo_de_vidrio_estrella_polar_duo_300.jpg';
                nombreAlt='trofeo de vidrio estrella polar duo';
                titulo='Trofeo de Vidrio Estrella Polar Duo';
                descripcion='Características del producto.';
                wimg=459; himg=590; wimgmax=1200;  himgmax=1832; 
      return  watchImg1200='../../assets/trofeos-vidrio/trofeo_de_vidrio_estrella_polar_duo_1200.jpg';
      case 'trofeo-de-vidrio-merlyn': watchImg300='../../assets/trofeos-vidrio/trofeo_de_vidrio_merlyn_300.jpg';
                nombreAlt='trofeo de vidrio merlyn';
                titulo='Trofeo de Vidrio Merlyn';
                descripcion='Características del producto.';
                wimg=470; himg=747; wimgmax=1200;  himgmax=1832; 
        return  watchImg1200='../../assets/trofeos-vidrio/trofeo_de_vidrio_merlyn_1200.jpg';
      case 'trofeo-de-vidrio-morelia-gris-metal-marmol': watchImg300='../../assets/trofeos-vidrio/trofeo_de_vidrio_morelia_gris_metal_marmol_300.jpg';
                nombreAlt='trofeo de vidrio morelia gris metal marmol';
                titulo='Trofeo de Vidrio Morelia Gris Metal Marmol';
                descripcion='Características del producto.';
                wimg=470; himg=556; wimgmax=1200;  himgmax=1832; 
      return  watchImg1200='../../assets/trofeos-vidrio/trofeo_de_vidrio_morelia_gris_metal_marmol_1200.jpg';
      case 'trofeo-de-vidrio-octogonal': watchImg300='../../assets/trofeos-vidrio/trofeo_de_vidrio_octogonal_300.jpg';
                nombreAlt='trofeo de vidrio octogonal';
                titulo='Trofeo de Vidrio Octogonal';
                descripcion='Características del producto.';
                wimg=470; himg=537; wimgmax=1200;  himgmax=1832; 
      return  watchImg1200='../../assets/trofeos-vidrio/trofeo_de_vidrio_octogonal_1200.jpg';
      case 'trofeo-de-vidrio-rectangular': watchImg300='../../assets/trofeos-vidrio/trofeo_de_vidrio_rectangular_300.jpg';
                nombreAlt='trofeo de vidrio rectangular';
                titulo='Trofeo de Vidrio Rectangular';
                descripcion='Características del producto.';
                wimg=470; himg=626; wimgmax=1200;  himgmax=1832; 
      return  watchImg1200='../../assets/trofeos-vidrio/trofeo_de_vidrio_rectangular_1200.jpg';
      case 'trofeo-de-vidrio-soberin-transparente': watchImg300='../../assets/trofeos-vidrio/trofeo_de_vidrio_soberin_transparente_300.jpg';
                nombreAlt='trofeo de vidrio soberin transparente';
                titulo='Trofeo de Vidrio Soberin Transparente';
                descripcion='Características del producto.';
                wimg=470; himg=643; wimgmax=1200;  himgmax=1832; 
      return  watchImg1200='../../assets/trofeos-vidrio/trofeo_de_vidrio_soberin_transparente_1200.jpg';
      case 'trofeo-de-vidrio-tetra': watchImg300='../../assets/trofeos-vidrio/trofeo_de_vidrio_tetra_300.jpg';
                nombreAlt='trofeo de vidrio tetra';
                titulo='Trofeo de Vidrio Tetra';
                descripcion='Características del producto.';
                wimg=470; himg=590; wimgmax=1200;  himgmax=1832; 
      return  watchImg1200='../../assets/trofeos-vidrio/trofeo_de_vidrio_tetra_1200.jpg';
      case 'placa-de-bronce-fundido': watchImg300='../../assets/placas-de-inauguracion/bronce-fundido/placa_de_bronce_fundido_300.jpg';
                nombreAlt='placa de bronce fundido';
                titulo='Placa de Bronce Fundido';
                descripcion='Características del producto.';
                wimg=498; himg=370; wimgmax=1993;  himgmax=1482; 
      return  watchImg1200='../../assets/placas-de-inauguracion/bronce-fundido/placa_de_bronce_fundido_1200.jpg';
      case 'placa-de-bronce-fundido-inen': watchImg300='../../assets/placas-de-inauguracion/bronce-fundido/placa_de_bronce_fundido_inen_300.jpg';
      nombreAlt='placa de bronce fundido inen';
      titulo='Placa de Bronce Fundido INEN';
      descripcion='Características del producto.';
      wimg=470; himg=638; wimgmax=1200;  himgmax=1832; 
      return  watchImg1200='../../assets/placas-de-inauguracion/bronce-fundido/placa_de_bronce_fundido_inen_1200.jpg';
      case 'placa-de-bronce-fundido-pcm': watchImg300='../../assets/placas-de-inauguracion/bronce-fundido/placa_de_bronce_fundido_pcm_300.jpg';
      nombreAlt='placa de bronce fundido pcm';
      titulo='Placa de Bronce Fundido PCM';
      descripcion='Características del producto.';
      wimg=498; himg=370; wimgmax=1993;  himgmax=1482; 
      return  watchImg1200='../../assets/placas-de-inauguracion/bronce-fundido/placa_de_bronce_fundido_pcm_1200.jpg';
      case 'placa-de-bronce-fundido-puente': watchImg300='../../assets/placas-de-inauguracion/bronce-fundido/placa_de_bronce_fundido_puente_300.jpg';
      nombreAlt='placa de bronce fundido puente';
      titulo='Placa de Bronce Fundido Puente';
      descripcion='Características del producto.';
      wimg=498; himg=370; wimgmax=1993;  himgmax=1482; 
      return  watchImg1200='../../assets/placas-de-inauguracion/bronce-fundido/placa_de_bronce_fundido_puente_1200.jpg';
      case 'placa-de-lamina-bronce': watchImg300='../../assets/placas-de-inauguracion/lamina-bronce/placa_de_lamina_bronce_300.jpg';
      nombreAlt='placa de lamina bronce';
      titulo='Placa de Lamina Bronce';
      descripcion='Características del producto.';
      wimg=498; himg=370; wimgmax=1993;  himgmax=1482; 
      return  watchImg1200='../../assets/placas-de-inauguracion/lamina-bronce/placa_de_lamina_bronce_1200.jpg';
      case 'marmol-claro': watchImg300='../../assets/placas-de-inauguracion/marmol-granito/marmol_claro_300.jpg';
      nombreAlt='marmol claro';
      titulo='Marmol Claro';
      descripcion='Características del producto.';
      wimg=498; himg=370; wimgmax=1993;  himgmax=1482;
      return  watchImg1200='../../assets/placas-de-inauguracion/marmol-granito/marmol_claro_1200.jpg';
      
      case 'placa-carrara': watchImg300='../../assets/placas-de-inauguracion/marmol-granito/placa_carrara_300.jpg';
      nombreAlt='placa carrara';
      titulo='Placa Carrara';
      descripcion='Características del producto.';
      wimg=470; himg=729; wimgmax=1200;  himgmax=1832;
      return  watchImg1200='../../assets/placas-de-inauguracion/marmol-granito/placa_carrara_1200.jpg';

      case 'placa-de-granito': watchImg300='../../assets/placas-de-inauguracion/marmol-granito/placa_de_granito_300.jpg';
      nombreAlt='placa de granito';
      titulo='Placa de Granito';
      descripcion='Características del producto.';
      wimg=470; himg=565; wimgmax=1200;  himgmax=1832;
      return  watchImg1200='../../assets/placas-de-inauguracion/marmol-granito/placa_de_granito_1200.jpg';

      case 'placa-de-marmol': watchImg300='../../assets/placas-de-inauguracion/marmol-granito/placa_de_marmol_300.jpg';
      nombreAlt='placa de marmol';
      titulo='Placa de Marmol';
      descripcion='Características del producto.';
      wimg=470; himg=654; wimgmax=1200;  himgmax=1832;
      return  watchImg1200='../../assets/placas-de-inauguracion/marmol-granito/placa_de_marmol_1200.jpg';

      case 'placa-de-marmol-con-soportes': watchImg300='../../assets/placas-de-inauguracion/marmol-granito/placa_de_marmol_con_soportes_300.jpg';
      nombreAlt='placa de marmol con soportes';
      titulo='Placa de Marmol con Soportes';
      descripcion='Características del producto.';
      wimg=498; himg=370; wimgmax=1993;  himgmax=1482; 
      return  watchImg1200='../../assets/placas-de-inauguracion/marmol-granito/placa_de_marmol_con_soportes_1200.jpg';

      case 'placa-de-marmol-marfil': watchImg300='../../assets/placas-de-inauguracion/marmol-granito/placa_de_marmol_marfil_300.jpg';
      nombreAlt='placa de marmol marfil';
      titulo='Placa de Marmol Marfil';
      descripcion='Características del producto.';
      wimg=470; himg=626; wimgmax=1200;  himgmax=1832;
      return  watchImg1200='../../assets/placas-de-inauguracion/marmol-granito/placa_de_marmol_marfil_1200.jpg';

      case 'placa-de-lamina-acrilica-plateada': watchImg300='../../assets/placas-de-inauguracion/placa-acrilica/placa_de_lamina_acrilica_plateada_300.jpeg';
      nombreAlt='placa de lamina acrilica plateada';
      titulo='Placa de Lamina Acrilica Plateada';
      descripcion='Características del producto.';
      wimg=470; himg=589; wimgmax=1200;  himgmax=1832; 
      return  watchImg1200='../../assets/placas-de-inauguracion/placa-acrilica/placa_de_lamina_acrilica_plateada_1200.jpeg';
 
      case 'placa-acrilica-serigrafia': watchImg300='../../assets/placas-de-inauguracion/placa-acrilica-serigrafia/placa_acrilica_serigrafia_300.jpeg';
      nombreAlt='placa acrilica serigrafia';
      titulo='Placa Acrilica Serigrafia';
      descripcion='Características del producto.';
      wimg=470; himg=755; wimgmax=1200;  himgmax=1832; 
      return  watchImg1200='../../assets/placas-de-inauguracion/placa-acrilica-serigrafia/placa_acrilica_serigrafia_1200.jpeg';
 
      case 'placa-de-vidrio': watchImg300='../../assets/placas-de-inauguracion/placas-de-vidrio/placa_de_vidrio_300.jpg';
      nombreAlt='placa de vidrio';
      titulo='Placa de Vidrio';
      descripcion='Características del producto.';
      wimg=470; himg=635; wimgmax=1200;  himgmax=1832; 
      return  watchImg1200='../../assets/placas-de-inauguracion/placas-de-vidrio/placa_de_vidrio_1200.jpg';
 
      case 'placa-de-vidrio-letrero': watchImg300='../../assets/placas-de-inauguracion/placas-de-vidrio/placa_de_vidrio_letrero_300.jpg';
      nombreAlt='placa de vidrio letrero';
      titulo='Placa de Vidrio Letrero';
      descripcion='Características del producto.';
      wimg=498; himg=370; wimgmax=1993;  himgmax=1482; 
      return  watchImg1200='../../assets/placas-de-inauguracion/placas-de-vidrio/placa_de_vidrio_letrero_1200.jpg';
 
      case 'placa-de-vidrio-mdf': watchImg300='../../assets/placas-de-inauguracion/placas-de-vidrio/placa_de_vidrio_mdf_300.jpg';
      nombreAlt='placa de vidrio mdf';
      titulo='Placa de Vidrio MDF';
      descripcion='Características del producto.';
      wimg=498; himg=370; wimgmax=1993;  himgmax=1482; 
      return  watchImg1200='../../assets/placas-de-inauguracion/placas-de-vidrio/placa_de_vidrio_mdf_1200.jpg';
 
      case 'placa-de-vidrio-pavonado': watchImg300='../../assets/placas-de-inauguracion/placas-de-vidrio/placa_de_vidrio_pavonado_300.jpg';
      nombreAlt='placa de vidrio pavonado';
      titulo='Placa de Vidrio Pavonado';
      descripcion='Características del producto.';
      wimg=470; himg=698; wimgmax=1200;  himgmax=1832; 
      return  watchImg1200='../../assets/placas-de-inauguracion/placas-de-vidrio/placa_de_vidrio_pavonado_1200.jpg';
 
      case 'trofeo-de-madera-c': watchImg300='../../assets/trofeos-variados/trofeo_de_madera_c_300.jpg';
      nombreAlt='trofeo de madera c';
      titulo='Trofeo de Madera C';
      descripcion='Características del producto.';
      wimg=498; himg=454; wimgmax=1993;  himgmax=1482; 
      return  watchImg1200='../../assets/trofeos-variados/trofeo_de_madera_c_1200.jpg';
 
      case 'trofeo-de-madera-contry': watchImg300='../../assets/trofeos-variados/trofeo_de_madera_contry_300.jpg';
      nombreAlt='trofeo de madera contry';
      titulo='Trofeo de Madera Contry';
      descripcion='Características del producto.';
      wimg=470; himg=770; wimgmax=1200;  himgmax=1832; 
      return  watchImg1200='../../assets/trofeos-variados/trofeo_de_madera_contry_1200.jpg';
 
      case 'trofeo-de-madera-elite': watchImg300='../../assets/trofeos-variados/trofeo_de_madera_elite_300.jpg';
      nombreAlt='trofeo de madera elite';
      titulo='Trofeo de Madera Elite';
      descripcion='Características del producto.';
      wimg=400; himg=880; wimgmax=1200;  himgmax=1832; 
      return  watchImg1200='../../assets/trofeos-variados/trofeo_de_madera_elite_1200.jpg';
 
      case 'trofeo-de-madera-eros': watchImg300='../../assets/trofeos-variados/trofeo_de_madera_eros_300.jpg';
      nombreAlt='trofeo de madera eros';
      titulo='Trofeo de Madera Eros';
      descripcion='Características del producto.';
      wimg=470; himg=698; wimgmax=1200;  himgmax=1832; 
      return  watchImg1200='../../assets/trofeos-variados/trofeo_de_madera_eros_1200.jpg';
 
      case 'trofeo-de-madera-trunca': watchImg300='../../assets/trofeos-variados/trofeo_de_madera_trunca_300.jpg';
      nombreAlt='trofeo de madera trunca';
      titulo='Trofeo de Madera Trunca';
      descripcion='Características del producto.';
      wimg=498; himg=370; wimgmax=1993;  himgmax=1482; 
      return  watchImg1200='../../assets/trofeos-variados/trofeo_de_madera_trunca_1200.jpg';
 

      default:
        return null;
    }
  })()}          

    const ShowProductDetail = () => {     
    return (
      <>            
  
         <div className="row mt-4">
         <div className="col-md-6"> 
            <ReactImageMagnify 
              imageProps={{
                  alt: nombreAlt,
                  isFluidWidth: true,
                  src: watchImg300,
                  width: wimg,
                  height: himg
                }}
              magnifiedImageProps={{
                  src: watchImg1200,
                  width: wimgmax,
                  height: himgmax
              }} 
              
              onActivationChanged={function noRefCheck(){}}
              onDetectedEnvironmentChanged={function noRefCheck(){}}
              onPositionChanged={function noRefCheck(){}}
              portalProps={{
                horizontalOffset: 20
              }}
              />   
              
          </div>
          <div className="col-md-6">       
          <p className="title">{titulo}</p>
          <p className="desc">{descripcion}</p> 
        </div>     
        </div>    
             
      </>
    );
  };
  
   

  return (
    <>
      <div className="container my-3 py-3">
        <div className="row">
          <div className="col-12">
            <h2 className="display-5 text-center">
            Detalle de Producto 
            </h2>
            <hr />
          </div>
        </div>
        <div className="row justify-content-center">
        <ShowProductDetail />
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
