import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const Home = () => {
  return (
    <>
      <div className="hero border-1 pb-3">
        <div className="card bg-dark text-white border-1 mx-3">
          <Carousel  showThumbs={false}
              infiniteLoop={true}
              autoPlay={true}
              interval={3000}
               >
            <div>
                <img src="assets/premiacion.jpg" alt="premiacion" />               
            </div>
            <div>
                <img src="assets/trofeos.jpg" alt="trofeos"/>
            </div>   
            <div>
                <img src="assets/acrilicas-bronce-fundido-marmol-granito.jpg" alt="acrilicas-bronce-fundido-marmol-granito"/>
           </div>               
          </Carousel>         
        </div>        
      </div>
    </>
  );
};

export default Home;
